import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ pageContext: { data } }) => (
  <Layout>
    {/* <SEO title="index" /> */}
    <h1>INDEX PAGE YO</h1>

    <nav id="primary">
      {data.pages.map(section => (
        <ol>
          <li>
            <Link to={section.url}>{section.title}!!!</Link>
            <ol>
              {!!section.pages &&
                section.pages.map(({ title, url }) => (
                  <li>
                    <Link to={url}>{title}!</Link>
                  </li>
                ))}
            </ol>
          </li>
        </ol>
      ))}
    </nav>

    {/* <pre>
      <code>{JSON.stringify(data, 0, 2)}</code>
    </pre> */}
  </Layout>
)

export default IndexPage
